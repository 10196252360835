import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { SafeHtmlPipe } from './core/pipes/safe-html.pipe';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './core/layout/header/header.component';
import { SidebarComponent } from './core/layout/sidebar/sidebar.component';
import { LoginComponent } from './core/authentication/pages/login/login.component';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxDropDownBoxModule,
  DxFileUploaderModule,
  DxFormModule,
  DxHtmlEditorModule,
  DxLookupModule,
  DxNumberBoxModule,
  DxSelectBoxModule,
  DxTagBoxModule,
  DxTextAreaModule,
  DxScrollViewModule,
  DxPopupModule,
} from 'devextreme-angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LanguageService } from './core/services/language.service';
import { JwtModule } from '@auth0/angular-jwt';
import {
  DxAccordionModule,
  DxSliderModule,
  DxTemplateModule,
} from 'devextreme-angular';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';

import * as deMessages from 'devextreme/localization/messages/de.json';
import { loadMessages, locale } from 'devextreme/localization';

// var deMessages = require('devextreme/localization/messages/de.json');

import { SharedModule } from './components/shared.module';
import { FooterComponent } from './core/layout/footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    LoginComponent,
    SafeHtmlPipe,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    DxDataGridModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    DxAccordionModule,
    DxSliderModule,
    DxTemplateModule,
    DxFormModule,
    DxButtonModule,
    DxDropDownBoxModule,
    DxTagBoxModule,
    DxTextAreaModule,
    DxScrollViewModule,
    DxHtmlEditorModule,
    DxNumberBoxModule,
    DxLookupModule,
    DxFileUploaderModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FontAwesomeModule,
    DxPopupModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: [
          'api.fra-gh-plattform.localhost',
          'devstage-api.fra-gh-plattform.de',
          'flightstage-api.fra-gh-plattform.de',
          'api.fra-gh-plattform.de',
          'api.fra-gh-plattform.ddev.site'
        ],
        disallowedRoutes: ['*/oauth/token*'],
      },
    }),
    AppRoutingModule,
  ],
  providers: [LanguageService],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeDe);
    loadMessages(deMessages);
    locale('de');
  }
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function tokenGetter() {
  return localStorage.getItem('access_token');
}
