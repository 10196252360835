import { Component, HostListener, OnInit, ViewChild, ElementRef, OnChanges, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './http/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          transform: 'translate3d(100%,0,0)',
        })
      ),
      state(
        'out',
        style({
          transform: 'translate3d(0, 0, 0)',
        })
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out')),
    ]),
  ]
})
export class AppComponent implements OnInit {
  menuState: string = 'in';
  title = 'fra-gh-plattform';
  isLoggedIn = false;
  menuPosition: any;
  sticky = false;
  constructor(
    translate: TranslateService,
    public authService: AuthService,
    @Inject(DOCUMENT) private document: Document
  ) {
    translate.setDefaultLang('de-de');
    translate.use('de-de');
  }

  ngOnInit() {
    this.authService.getIsLoginSubject().subscribe((data) => {
      this.isLoggedIn = data;
      this.menuState = 'in';
    });

  }

  toggleSidebar() {
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }

  getHeight() {
    return window.innerHeight;
  }

  getHeightOverlay() {
    return window.innerHeight;
  }


}
