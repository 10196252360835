import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../http/auth.service';
import { Router } from '@angular/router';
import { LanguageService } from '../../../core/services/language.service';
import { PermissionGuard } from '../../../http/permission.guard';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Output() childEvent = new EventEmitter();
  faTimes = faTimes;

  constructor(
    public authService: AuthService,
    public permissionGuard: PermissionGuard,
    private router: Router,
    public languageService: LanguageService
  ) {}

  ngOnInit(): void {}

  toggleSidebar() {
    this.childEvent.emit();
  }

  logout() {
    this.authService.logout().subscribe(
      (data) => {
        localStorage.removeItem('access_token');
        // this.router.navigate(['/login']);
        location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${location.origin}/login`;
      },
      (error) => {}
    );
  }

  // adminLogout() {
  //   this.authService.logout().subscribe(
  //     (data) => {
  //       location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${location.origin}/login`;
  //     },
  //     (error) => {}
  //   );
  // }

  //TODO
  //admin only show for admin
}
