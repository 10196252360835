import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment.devstage';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  // Variables
  apiUrl = environment.apiUrl;
  /**
   * Constructor
   * @param http The http client object
   */
  constructor(private http: HttpClient) {}
  /**
   * Get the userData of the current logged in user
   */
  public getUser() {
    return this.http.get(this.apiUrl + 'api/user', { responseType: 'json' });
  }

  /**
   * Get the userData of the current logged in user
   */
  public getUserPerson() {
    const res = this.http.get(this.apiUrl + 'api/component/users/getuserdata', {
      responseType: 'json',
    });
    return res;
  }

  public acceptPrivacyPolicy() {
    return this.http.post(
      this.apiUrl + 'api/component/users/acceptPrivacyPolicy',
      {},
      { responseType: 'json' }
    );
  }
}
