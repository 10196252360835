import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// import ngx-translate and the http loader
import { TranslateModule } from '@ngx-translate/core';
import { QuickNavComponent } from './quick-nav/quick-nav.component';

@NgModule({
  declarations: [QuickNavComponent],
  imports: [CommonModule, HttpClientModule, TranslateModule, RouterModule],
  exports: [QuickNavComponent],
})
export class SharedModule {}
