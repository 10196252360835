import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
  Inject,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { AuthService } from '../../../http/auth.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @Output() childEvent = new EventEmitter();
  @ViewChild('stickyMenu')
  menuElement!: ElementRef;
  menuPosition: any;
  isLoggedIn = false;
  scrolled: boolean = false;
  sticky: boolean = false;

  constructor(
    public authService: AuthService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.authService.getIsLoginSubject().subscribe((data) => {
      this.isLoggedIn = data;
    });
  }

  ngAfterViewInit() {
    this.menuPosition = this.menuElement.nativeElement.offsetTop;
  }

  toggleSidebar() {
    this.childEvent.emit();
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll > this.menuPosition) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }
}
