import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-quick-nav',
  templateUrl: './quick-nav.component.html',
  styleUrls: ['./quick-nav.component.scss'],
  host: { class: 'quick-nav-app' },
  encapsulation: ViewEncapsulation.Emulated,
})
export class QuickNavComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}
}
