import {
  AfterViewInit,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../../http/auth.service';
import { LanguageService } from '../../../services/language.service';
import { ApiCrudService } from '../../../../http/api-crud.service';
import { UserService } from '../../../../http/user.service';
import { environment } from '@environment';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit {
  // Variables
  form: FormGroup;
  loading!: boolean;
  errors!: boolean;
  language!: string;
  country!: string;
  useAzureLogin!: boolean;
  showPopup = false;
  private acceptPrivacyPolicy$ = new BehaviorSubject<boolean>(false);

  acceptPrivacyPolicyButtonOptions: any = {
    width: 300,
    text: 'Accept',
    type: 'default',
    stylingMode: 'contained',
    onClick: () => {
      this.acceptConditions();
    }
  }


  constructor(
    fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    public languageService: LanguageService,
    private apiCrudService: ApiCrudService,
    private userService: UserService,
    public http: HttpClient,
    private route: ActivatedRoute
  ) {
    this.form = fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
    this.useAzureLogin = environment.useAzureLogin;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const otp = this.route.snapshot.queryParamMap.get('otp');
    console.log(otp);
    if (otp) {
      this.authService.loginOneTimePassword(otp).subscribe(
        (res: any) => {
          // Store the access token in the localstorage
          // localStorage.setItem('access_token', res.access_token);
          this.loading = false;
          if(localStorage.getItem('privacy_policy_accepted') !== "true") {
            this.showPopup = true;
          }else {
            this.acceptPrivacyPolicy$.next(true);
          }

          // Navigate to home page
          if (this.acceptPrivacyPolicy$.value) {
            this.router.navigate(['/procedural-instructions/dashboard']);
          }
        },
        (err: any) => {
          // This error can be internal or invalid credentials
          // You need to customize this based on the error.status code
          this.loading = false;
          this.errors = true;
        }
      );
    }
  }

  /**
   * Login the user based on the form values
   */
  login(): void {
    this.loading = true;
    this.errors = false;
    this.authService
      .login(this.controls.email.value, this.controls.password.value)
      .subscribe(
        (res: any) => {
          // Store the access token in the localstorage
          // localStorage.setItem('access_token', res.access_token);
          this.loading = false;
          if(localStorage.getItem('privacy_policy_accepted') !== "true") {
            this.showPopup = true;
          }else {
            this.acceptPrivacyPolicy$.next(true);
          }

          // Navigate to home page
          if (this.acceptPrivacyPolicy$.value) {
            this.router.navigate(['/procedural-instructions/dashboard']);
          }
        },
        (err: any) => {
          // This error can be internal or invalid credentials
          // You need to customize this based on the error.status code
          this.loading = false;
          this.errors = true;
        }
      );
  }

  loginAzure() {
    location.href = environment.apiUrl + 'login/azure';
  }

  getUser() {
    this.userService
      .getUser()
      .subscribe
      /* (data) => console.log(data),
      (err) => console.log(err)*/
      ();
  }

  /**
   * Getter for the form controls
   */
  get controls() {
    return this.form.controls;
  }

  acceptConditions() {
    //update the users privacy_policy_accepted to true
    this.userService
      .acceptPrivacyPolicy()
      .subscribe(
        (res: any) => {
          console.log('Privacy policy accepted');

          // Update the acceptPrivacyPolicy$ subject to true
          this.acceptPrivacyPolicy$.next(true);
        },
        (err: any) => {
          console.log('Error accepting privacy policy');
        }
      );

    //navigate to home page
    this.router.navigate(['/procedural-instructions/dashboard']);
    this.showPopup = false;
  }
}
