export class LanguageService {
  pathname = window.location.pathname;
  urlArray = this.pathname.split('/');
  //url language segment
  countrynav = this.urlArray[1];
  languagenav = this.urlArray[2];

  constructor() {}

  getCountry() {
    if (this.countrynav === '') {
      return 'us';
    }
    if (this.countrynav === undefined) {
      return 'us';
    }
    return this.countrynav;
  }

  getLanguage() {
    if (this.languagenav === undefined) {
      return 'en';
    }
    if (this.languagenav === '') {
      return 'en';
    }
    return this.languagenav;
  }

  /**
   * completes a link with the corrrect country/language pathing
   *
   * @param link
   */
  mutateLinkParams(...link: string[]) {
    return ['/', this.countrynav, this.languagenav, ...link];
  }
}
