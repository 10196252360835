<header id="topnav" class="p-3" #stickyMenu [class.sticky]="sticky">
  <div class="container-fluid">
    <nav class="navbar">
      <div class="container-fluid">
        <div
          class="navbar-brand"
          routerLink="/procedural-instructions/dashboard"
        >
          <img
            src="/assets/images/header/FRA-GH-Logo.svg"
            alt=""
            width="50"
            height="32"
            class="d-inline-block align-text-top fra-logo"
          />
          {{ "header.brandName" | translate }}
        </div>
        <div
          *ngIf="isLoggedIn"
          class="sidebar-toogle"
          (click)="toggleSidebar()"
        >
          <img
            src="/assets/images/header/toggle-sidebar.svg"
            alt=""
            width="35"
            height="9"
            class="d-inline-block toggle-sidebar-image"
          />
        </div>
      </div>
    </nav>
  </div>
</header>
