<div class="container login-start" id="login">
  <dx-popup
    [visible]="showPopup"
    [title]="'login.privacyPolicy' | translate"

    [closeOnOutsideClick]="false"
    [showCloseButton]="false"
  >
    <div *dxTemplate="let data of 'content'">
      <dx-scroll-view width="100%" height="100%">
        <div>
          <div class="caption">Lorem Ipsum</div>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nostrum
          saepe quisquam obcaecati rerum iure laudantium. Praesentium est qui,
          tenetur optio dolore suscipit dicta quaerat culpa placeat dignissimos
          alias tempore nisi? Lorem ipsum, dolor sit amet consectetur
          adipisicing elit. Nostrum saepe quisquam obcaecati rerum iure
          laudantium. Praesentium est qui, tenetur optio dolore suscipit dicta
          quaerat culpa placeat dignissimos alias tempore nisi? Lorem ipsum,
          dolor sit amet consectetur adipisicing elit. Nostrum saepe qπuisquam
          obcaecati rerum iure laudantium. Praesentium est qui, tenetur optio
          dolore suscipit dicta quaerat culpa placeat dignissimos alias tempore
          nisi? Lorem ipsum, dolor sit amet consectetur adipisicing elit.
          Nostrum saepe qπuisquam obcaecati rerum iure laudantium. Praesentium
          est qui, tenetur optio dolore suscipit dicta quaerat culpa placeat
          dignissimos alias tempore nisi? Lorem ipsum, dolor sit amet
          consectetur adipisicing elit. Nostrum saepe qπuisquam obcaecati rerum
          iure laudantium. Praesentium est qui, tenetur optio dolore suscipit
          dicta quaerat culpa placeat dignissimos alias tempore nisi? Lorem
          ipsum, dolor sit amet consectetur adipisicing elit. Nostrum saepe
          qπuisquam obcaecati rerum iure laudantium. Praesentium est qui,
          tenetur optio dolore suscipit dicta quaerat culpa placeat dignissimos
          alias tempore nisi? Lorem ipsum, dolor sit amet consectetur
          adipisicing elit. Nostrum saepe qπuisquam obcaecati rerum iure
          laudantium. Praesentium est qui, tenetur optio dolore suscipit dicta
          quaerat culpa placeat dignissimos alias tempore nisi? Lorem ipsum,
          dolor sit amet consectetur adipisicing elit. Nostrum saepe qπuisquam
          obcaecati rerum iure laudantium. Praesentium est qui, tenetur optio
          dolore suscipit dicta quaerat culpa placeat dignissimos alias tempore
          nisi? Lorem ipsum, dolor sit amet consectetur adipisicing elit.
          Nostrum saepe qπuisquam obcaecati rerum iure laudantium. Praesentium
          est qui, tenetur optio dolore suscipit dicta quaerat culpa placeat
          dignissimos alias tempore nisi? Lorem ipsum, dolor sit amet
          consectetur adipisicing elit. Nostrum saepe qπuisquam obcaecati rerum
          iure laudantium. Praesentium est qui, tenetur optio dolore suscipit
          dicta quaerat culpa placeat dignissimos alias tempore nisi? Lorem
          ipsum, dolor sit amet consectetur adipisicing elit. Nostrum saepe
          qπuisquam obcaecati rerum iure laudantium. Praesentium est qui,
          tenetur optio dolore suscipit dicta quaerat culpa placeat dignissimos
          alias tempore nisi? Lorem ipsum, dolor sit amet consectetur
          adipisicing elit. Nostrum saepe qπuisquam obcaecati rerum iure
          laudantium. Praesentium est qui, tenetur optio dolore suscipit dicta
          quaerat culpa placeat dignissimos alias tempore nisi? Lorem ipsum,
          dolor sit amet consectetur adipisicing elit. Nostrum saepe qπuisquam
          obcaecati rerum iure laudantium. Praesentium est qui, tenetur optio
          dolore suscipit dicta quaerat culpa placeat dignissimos alias tempore
          nisi?
        </div>
      </dx-scroll-view>
    </div>
    <dxi-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="center"
      [options]="acceptPrivacyPolicyButtonOptions"
    >
    </dxi-toolbar-item
  ></dx-popup>
  <div class="row">
    <div class="col-12">
      <form
        class="login-form"
        [formGroup]="form"
        (submit)="login()"
        *ngIf="!useAzureLogin"
      >
        <div class="login-card card">
          <h1 class="card-title">
            <strong>{{ "generic.login" | translate }}</strong>
          </h1>
          <div class="row" *ngIf="errors">
            <div class="col">
              <div
                class="alert alert-danger"
                [innerHTML]="'login.loginError' | translate"
              ></div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <input
                  type="email"
                  id="email"
                  formControlName="email"
                  [attr.placeholder]="'generic.email' | translate"
                  class="form-control"
                />
              </div>
              <div class="form-group form-password">
                <input
                  type="password"
                  id="password"
                  formControlName="password"
                  [attr.placeholder]="'generic.password' | translate"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </div>
        <button
          type="submit"
          class="btn btn-light login-button"
          [disabled]="loading"
        >
          {{ "generic.login" | translate }}
        </button>
        <div class="login-info" [innerHTML]="'login.info' | translate"></div>
      </form>
      <form
        class="azure-login-form"
        [formGroup]="form"
        (submit)="loginAzure()"
        *ngIf="useAzureLogin"
      >
        <div class="login-card card">
          <h1 class="card-title">
            <strong>{{ "generic.login" | translate }}</strong>
          </h1>
          <div class="row" *ngIf="errors">
            <div class="col">
              <div
                class="alert alert-danger"
                [innerHTML]="'login.loginError' | translate"
              ></div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              Mit Klick auf "Einloggen" werden Sie zu Microsoft weitergeleitet,
              um sich einzuloggen.
            </div>
          </div>
        </div>
        <button
          type="submit"
          class="btn btn-light login-button"
          [disabled]="loading"
        >
          {{ "generic.login" | translate }}
        </button>
        <div class="login-info" [innerHTML]="'login.info' | translate"></div>
        <div class="login-info cursor-pointer mt-5" routerLink="/imprint">
          Impressum
        </div>
      </form>
    </div>
  </div>
</div>
