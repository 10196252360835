import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-imprint-page',
  templateUrl: './imprint-page.component.html',
  styleUrls: ['./imprint-page.component.scss'],
  host: { class: 'imprint-page-app' },
  encapsulation: ViewEncapsulation.Emulated,
})
export class ImprintPageComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}
}
