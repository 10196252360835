import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './core/authentication/pages/login/login.component';
import { ImprintPageComponent } from './pages/imprint-page/imprint-page.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  { path: 'login', component: LoginComponent },
  { path: 'imprint', component: ImprintPageComponent },
  {
    path: 'procedural-instructions',
    data: {type:'procedural'},
    loadChildren: () =>
      import(
        './modules/procedural-instructions/procedural-instructions.module'
      ).then((m) => m.ProceduralInstructionsModule),
  },
  {
    path: 'employee-information',
    data: {type:'employee-information'},
    loadChildren: () =>
      import(
        './modules/procedural-instructions/procedural-instructions.module'
      ).then((m) => m.ProceduralInstructionsModule),
  },
  {
    path: 'safety-bulletins',
    data: {type:'safety-bulletin'},
    loadChildren: () =>
      import(
        './modules/procedural-instructions/procedural-instructions.module'
      ).then((m) => m.ProceduralInstructionsModule),
  },
  {
    path: 'trainings',
    loadChildren: () =>
      import('./modules/trainings/trainings.module').then(
        (m) => m.TrainingsModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
